<template>
  <v-container fluid fill-height class="bg-login" :style="'background-image: url('+background+')'">
      <v-row justify="center" >
          <v-col cols="12" md="6" lg="4" align="center">
              <v-card >
                  <v-card-text >
                      <v-row>
                          <v-col cols="12" align="center">
                              <img :src="logo" width="100%">
                          </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                          lazy-validation
                      >
                        <v-row>
                          <v-col cols="12"  class="pb-0" >
                              <v-text-field
                                  autofocus
                                  v-model="username"
                                  label="Username"
                                  outlined
                                  :prepend-inner-icon="'mdi-account'"
                                  :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                  required
                                  @keyup.enter="$refs.password.focus()"
                              ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-0"  >
                            <v-text-field
                                ref="password"
                                v-model="password"
                                label="Password"
                                outlined
                                :prepend-inner-icon="'mdi-lock'"
                                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show_password ? 'text' : 'password'"
                                :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                required
                                @click:append="show_password = !show_password"
                                @keyup.enter="bt_login_click()"
                            ></v-text-field>
                            <span style="color: red;" v-if="errorMessage != ''">{{ errorMessage }} </span>
                          </v-col>
                          
                          <v-col cols="12"  class="pb-0">
                              <v-btn
                              class="btn-login rounded-lg"
                              :loading="bt_loading"
                              :disabled="!valid || bt_loading"
                              @click="bt_login_click()">เข้าสู่ระบบ</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                  </v-card-text>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<style scoped>
 >>>.v-input__prepend-inner{
   margin-top: 10px;
 }
 .btn-login{
  background-color:#a9001d !important;
  font-size: 1em !important;
  width:100% !important;
  height:50px !important;
  color: #ffffff !important;
}
</style>

<script>
import axios from 'axios'
import { shopService_dotnet, agentService_dotnet, generalService_dotnet,branchService_dotnet } from '@/website/global'
import { load_languages } from '@/website/global_function'
import { token_api } from '@/website/backend/token' 
import { error } from 'pdf-lib';
 export default {
       data: () => ({
          bt_loading: false,
          valid: true,
          username: '',
          password: '',
          show_password: false,
          loading: false,
          staff_branch_id:null,
          logo:null,
          background: null,
          staff_shop_token:null,
          errorMessage : '', 
       }),

       async created () {
        localStorage.clear();
        var url = document.URL;
        const urlParams = new URLSearchParams(window.location.search);
        const branchid1 = urlParams.get('branchid');

        if(this.$route.query.staffbranchid === 'undefined'){
          this.staff_branch_id = null
        } else if ( this.$route.query.staffbranchid == 'null') {
          this.staff_branch_id = null
        } else if ( this.$route.query.staffbranchid == null) {
          this.staff_branch_id = null
        } else if (this.$route.query.staffbranchid == '') {
          this.staff_branch_id = null
        } else {
          this.staff_branch_id = this.$route.query.staffbranchid
        }

        if(this.staff_branch_id!=null){
          localStorage.setItem('staff_branch_id', this.staff_branch_id) 
        } 
        

        if(this.$route.query.token === 'undefined'){
          this.staff_shop_token = null
        } else if ( this.$route.query.token == 'null') {
          this.staff_shop_token = null
        } else if ( this.$route.query.token == null) {
          this.staff_shop_token = null
        } else if (this.$route.query.token == '') {
          this.staff_shop_token = null
        } else {
          this.staff_shop_token = this.$route.query.token
        }

         var doman = (url.split("/"))[2]
         var subdomain = doman.split(".")
         let branchid= 0;
         subdomain = subdomain[0]
         if (subdomain == 'fastpac' || subdomain == 'dev-fastpac')
         {
           branchid=83
         }
         else  if (url.includes( "halofulfillment")){
           branchid=301
         }
         else  if (url.includes( "bco5")){
           branchid=581
         }
         else  if (url.includes( "passme")){
           branchid=578
         }
         else  if (url.includes( "save")){
           branchid=595
         }
         else  if (url.includes( "amnow")){
           branchid=537
         }
         else if (url.includes("placefulfillment")){
          branchid=633
         }
         else if (url.includes("osswarehouse")){
          branchid=572
         }
         else  if (url.includes( "bp-packing")){
           branchid=590
         }
         else if (url.includes("sabaidee")){
            branchid=695
          }
         else if(branchid1!=null){
          branchid=branchid1
         }
         else if(url.includes('oasys') && this.staff_shop_token==null)
         {
          this.$router.push("/loginOasys");
         }

         
         try {
          await axios.post(branchService_dotnet+'Branch/get-branch-info?BranchID='+branchid, {}, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+  token_api }})
              .then(response =>
              {
                this.background = response.data.photo_bg_shop_login
                this.logo = response.data.logoLogin
              })
        } catch (error) {
          //do nothing
        }

         if(this.background==null){
          this.background = "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/bglogin.jpg"
         }
         if(this.logo==null){
          this.logo = "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/packhai-logo-color.png"
         }


         if(localStorage.getItem('staff_id') == null || localStorage.getItem('staff_id') === 'undefined'){
           localStorage.setItem('navigete', JSON.stringify([
               {
                   "title": "Dashboard",
                   "path": "/dashboard"
               },
               {
                   "title": "หน้าแรก",
                   "path": "/dashboard"
               }
           ]))
 
           if(this.staff_shop_token !=null){
             await this.get_user_and_password()
           }
           this.url_ref = this.$route.query.ref
         } else {
           if(this.staff_shop_token !=null){
             await this.get_user_and_password()
           }

           if(this.staff_shop_token==null){
             this.$router.push('/dashboard')
           }
         }

         this.query_url_to_push = null
         if("url" in this.$route.query){
           this.query_url_to_push = this.$route.query.url
         }
       },

       methods: {
        load_languages,
           async bt_login_click() {
               if (this.$refs.form.validate()) {
                   this.snackbar = true
                   this.bt_loading = true
                   let response = await axios.post(shopService_dotnet+'StaffAuthentication/staff-shop-login', {
                     'username': this.username,
                     'password': this.password
                   }, {headers: this.header_token})
                       var shop_id = response.data.shop_id
                       var staff_id = response.data.staff_id
                       var branch_id = response.data.branch_id
                       var is_master_user = response.data.is_master_user
                       var status = response.data.status
                       var data_permission = response.data.staffPermissionResponse ;
                       var dict_tmp_edit = {}
                       for (var i = 0; i < data_permission.length; i++) {
                         dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
                       }
                       if (status == true) {
                           localStorage.setItem('shop_id', shop_id)
                           localStorage.setItem('staff_id', staff_id)
                           localStorage.setItem('shop',  JSON.stringify(response.data.shop))
                           localStorage.setItem('shop_feature',  JSON.stringify(response.data.shopFeatureList))
                           localStorage.setItem('shop_id', shop_id)
                           localStorage.setItem("branch_id", branch_id)
                           localStorage.setItem("is_master_user", is_master_user)
                           localStorage.setItem('authorization_token', response.data.token) 
                           localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))
                           localStorage.setItem('permission_code', JSON.stringify(response.data.shopPermission_codes_new))

                           this.header_token={'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')}
                           // set code to localStorage
                          //  for (var data_code of response.data.shopPermission_codes_new) {
                          //    localStorage.setItem(data_code.menuCode, data_code.authorize)
                          //  }
                           await this.load_languages();
                           if(this.query_url_to_push == null){
                             if (typeof(this.url_ref) ==='undefined') {
                              var firstRouter = response.data.shopPermission_codes_new.filter((data) => data.authorize != null);                               
                              
                              for(var routerToPage of firstRouter){         
                                                      
                                if(routerToPage.id == 16){
                                   this.$router.push('/dashboard')
                                   return
                                  }
                                if(routerToPage.id == 24){ 
                                  this.$router.push('/order')
                                  return
                                }
                                if(routerToPage.id == 20){
                                   this.$router.push('/my-stock')
                                   return
                                  }
                                 

                              }
                               this.$router.push('/dashboard')

                        
                             } else {
                               var url_decode = decodeURIComponent(this.url_ref)
                              this.$router.push(url_decode)
                             }
                           } else {
                             this.$router.push('/'+this.query_url_to_push)
                           }
                       } 
                        else if(response.data.is_not_found){
                          this.errorMessage = "ไม่พบผู้ใช้นี้";
                          
                        }
                        else if(response.data.is_wrong_password){
                          this.errorMessage = "รหัสผ่านผิดกรุณาลองอีกครั้ง";
                          
                        }
                       else if(response.data.is_lock ==  true){
                         this.$swal({ 
                            type: 'error',
                            text: 'ผู้ใช้นี้ถูกล็อกกรุณาเปลี่ยนรหัสผ่านใหม่',
                         })
                         this.bt_loading = false
                       }
                       else {
                           this.$swal({
                               position: 'top',
                               type: 'info',
                               text: 'ไม่พบสมาชิก',
                           })
                         
                       }
                      this.bt_loading = false
               }
           },
          async get_languages_file(){
            this.set_language = localStorage.getItem("set_language");

            let response = await axios.post(generalService_dotnet +"General/get-language-file",{}, { headers: this.header_token });

            if (this.set_language != (null && undefined)) {
              
              this.use_language =response.data.Language[this.set_language]
              localStorage.setItem("language_data",    JSON.stringify(this.use_language));
            } else {
              this.use_language = response.data.Language["TH"];
              localStorage.setItem("set_language", "TH");
            }
      

          },
           async get_user_and_password () {
            this.errorMessage = '';
             let response2 = await axios.post(shopService_dotnet+'StaffAuthentication/staff-shop-login', {
               'token': this.staff_shop_token
             },
             { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+ token_api }})

 
               var shop_id = response2.data.shop_id
               var staff_id = response2.data.staff_id
               var branch_id = response2.data.branch_id
               var is_master_user = response2.data.is_master_user
               var status = response2.data.status

               var data_permission = response2.data.staffPermissionResponse ;
               var dict_tmp_edit = {}
               for (var i = 0; i < data_permission.length; i++) {
                 dict_tmp_edit[data_permission[i]['nameEh']] = data_permission[i]['isAllow']
               }
               
               if (status == true) {
                   localStorage.setItem('shop_id', shop_id)
                   localStorage.setItem('staff_id', staff_id)
                   localStorage.setItem('shop',  JSON.stringify(response2.data.shop))
                   localStorage.setItem('shop_feature',  JSON.stringify(response2.data.shopFeatureList))
                   localStorage.setItem("branch_id", branch_id)
                   localStorage.setItem("is_master_user", is_master_user)
                   localStorage.setItem('authorization_token', response2.data.token)
                  //  localStorage.setItem('authorization_token', 'asdadadd56as4da54da5s6d65as65d46')
                   // localStorage.setItem('permission', JSON.stringify(response2.data.permission))
                   localStorage.setItem('permission', JSON.stringify(dict_tmp_edit))                   
                   localStorage.setItem('permission_code', JSON.stringify(response2.data.shopPermission_codes_new))

                   // set code to localStorage
                  //  for (var data_code of response2.data.shopPermission_codes_new) {
                  //    localStorage.setItem(data_code.menuCode, data_code.authorize)
                  //  }
                     //this.load_languages();
                   if(this.query_url_to_push == null){
                     if (typeof(this.url_ref) ==='undefined') {
                      var firstRouter2 = response2.data.shopPermission_codes_new.filter((data) => data.authorize != null);
                          
                               for(var routerToPage of firstRouter2){
                                
                                 if(routerToPage.id == 16){ 
                                  this.$router.push('/dashboard')
                                  return
                                }
                                 if(routerToPage.id == 24){
                                  this.$router.push('/order')
                                  return
                                }
                                 if(routerToPage.id == 20){
                                  this.$router.push('/my-stock')
                                return
                               }
                                

                               }
                       this.$router.push('/dashboard')
                     } else {
                       var url_decode = decodeURIComponent(this.url_ref)
                       this.$router.push(url_decode)
                     }
                   }else {
                     this.$router.push('/'+this.query_url_to_push)
                   }

               }
               else if(response2.data.is_not_found){
                this.errorMessage = "ไม่พบผู้ใช้นี้";
                
               }
               else if(response2.data.is_wrong_password){
                this.errorMessage = "รหัสผ่านผิดกรุณาลองอีกครั้ง";
                
               }
               else if(response2.data.is_lock ==  true){
                         this.$swal({
                               position: 'top',
                               type: 'error',
                               text: 'ผู้ใช้นี้ถูกล็อกกรุณาเปลี่ยนรหัสผ่านใหม่',
                           })
                           this.bt_loading = false
                       }
               else {
                   this.$swal({
                       position: 'top',
                       type: 'info',
                       text: 'ไม่พบสมาชิก',
                   })
                   this.bt_loading = false
               }
           },
       }
   }
</script>
<style>
   .container.fill-height > .row
   {
       max-width: none;
   }
   .button-facebook{
      color:white;
      min-width: 150px;
      background-color: #000000a1;
      height: 2.5rem;
      border-radius: 2rem;
      font-weight: 400;
      font-size: 0.8rem;
    }
    .fb-signin-button {
      /* This is where you control how the button looks. Be creative! */
      display: inline-block;
      padding: 17px;
      width: 100%;
      border-radius: 3px;
      background-color: #4267b2;
      color: #fff;
    }
</style>
