<template>
  <div class="mt-0" align="center">

  </div>
</template>
<script>
import axios from 'axios'
import { ecomService_dotnet } from '@/website/global'
import { DateNow, FormatInvoice, formatMoney } from '@/website/global_function'
import { header_token } from '@/website/backend/token'
  export default {
    components: {
      // 'barcode': VueBarcode,
      // VueQrcode
    },
    data: () => ({
      shop_id: null,
      url_callback: null
    }),
    async created () {
      this.shop_id = localStorage.getItem('shop_id')
    
      this.url_callback = window.location.href
      if(this.$route.query.url!=null){
        var path = this.$route.query.url.toString();

        window.location.assign(decodeURI(path)+"?code="+this.$route.query.code+"&shop_id="+this.$route.query.shop_id);
      }
      if(this.shop_id != null){
        await this.update_data_call_back()
      }else {
        // encode fullphat
        var url_encode = encodeURIComponent(this.url_callback)
        this.$router.push('/login?ref='+ url_encode)
      }
    },
    watch: {
    },
    methods: {
      // DateNow,
      async update_data_call_back() {
        this.loading = true
        var bool_check_init_shopee = localStorage.getItem('check_init_shopee')
        var warehouseid = localStorage.getItem('shopee_connect_warehouse')
        var storeCode = localStorage.getItem('storeCode')
        var shopee_init_timerange = JSON.parse(localStorage.getItem('shopee_init_timerange'))
        if(bool_check_init_shopee == 'false'){
          bool_check_init_shopee = false
        } else if (bool_check_init_shopee == 'true') {
          bool_check_init_shopee = true
        }
        let response1 = await axios.post(ecomService_dotnet+'Shopee/callback',{
          "shopID": this.shop_id ,
          "isInit": bool_check_init_shopee,
          "warehouseID":warehouseid,
          "callbackUrl": this.url_callback,
          "initTimeRanges":shopee_init_timerange,
          'storeCode': storeCode
        } , { headers: header_token})
        this.loading = false
        localStorage.removeItem("check_init_shopee");
        localStorage.removeItem("shopee_connect_warehouse");
        localStorage.removeItem("shopee_init_timerange");
        localStorage.removeItem("storeCode");
        this.$router.push('/connect')
        // let response = await axios.post(shopCallbackService+'shopee/callback', {
        //   'shop_id': this.shop_id,
        //   'check_init_shopee': localStorage.getItem('check_init_shopee'),
        //   'url_callback' : this.url_callback
        // })
        // if (response.data.status == 'Success') {
        //   // console.log('end');
        //    localStorage.removeItem("check_init_shopee");
        //     // this.update_data_connect()
        //     this.$router.push('/connect')
        // }
        // this.loading = false
      },

      // async update_data_connect () {
      //   let response = await axios.post(shopCallbackService+'shopee/update_data_monthlyfee', {
      //     'shop_id': localStorage.getItem('shop_id'),
      //     'staff_id' : localStorage.getItem('staff_id'),
      //     'branch_id' : localStorage.getItem('branch_id')
      //   })
      //   if (response.data.status == 'Success') {
      //     // console.log('end');
      //     console.log('update monthlyfee success');
      //   }
      //   this.loading = false
      // },
    },
  }
</script>
